exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-bharat-mobility-global-expo-2025-shaping-future-of-mobility-narendra-modi-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/automobiles/bharat-mobility-global-expo-2025-shaping-future-of-mobility-narendra-modi/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-bharat-mobility-global-expo-2025-shaping-future-of-mobility-narendra-modi-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-hyundai-creta-electric-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/automobiles/hyundai-creta-electric/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-hyundai-creta-electric-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-tata-sierra-ice-unveiled-bharat-mobility-expo-2025-price-features-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/automobiles/tata-sierra-ice-unveiled-bharat-mobility-expo-2025-price-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-tata-sierra-ice-unveiled-bharat-mobility-expo-2025-price-features-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-triumph-thruxton-400-spotted-again-here-are-likely-launch-date-expected-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/automobiles/triumph-thruxton-400-spotted-again-here-are-likely-launch-date-expected/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-automobiles-triumph-thruxton-400-spotted-again-here-are-likely-launch-date-expected-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ajith-kumar-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/ajith-kumar/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ajith-kumar-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-allu-arjun-pushpa-2-box-office-collection-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/allu-arjun-pushpa2-box-office-collection/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-allu-arjun-pushpa-2-box-office-collection-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-anurag-basu-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/anurag-basu/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-anurag-basu-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-bigg-boss-18-winner-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/bigg-boss-18-winner/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-bigg-boss-18-winner-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-chhava-movie-trailer-sambhaji-maharaj-vicky-kaushal-laxman-uteker-akshaye-khanna-aurangzeb-rashmika-mandanna-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/chhava-movie-trailer-sambhaji-maharaj-vicky-kaushal-laxman-uteker-akshaye-khanna-aurangzeb-rashmika-mandanna/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-chhava-movie-trailer-sambhaji-maharaj-vicky-kaushal-laxman-uteker-akshaye-khanna-aurangzeb-rashmika-mandanna-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-dhoom-4-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/dhoom-4/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-dhoom-4-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-emergency-movie-review-kangana-ranauts-unflinching-masterpiece-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/emergency-movie-review-kangana-ranauts-unflinching-masterpiece/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-emergency-movie-review-kangana-ranauts-unflinching-masterpiece-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-golden-globes-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/golden-globes-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-golden-globes-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-himesh-reshammiya-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/himesh-reshammiya/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-himesh-reshammiya-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-jailer-2-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/Jailer-2/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-jailer-2-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-james-cameron-says-about-avatar-3-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/james-cameron-says-about-avatar-3/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-james-cameron-says-about-avatar-3-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-kunal-kamra-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/kunal-kamra/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-kunal-kamra-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-priyanka-chopra-backed-anuja-film-makes-it-to-oscar-2025-oscar-nominations-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/priyanka-chopra-backed-anuja-film-makes-it-to-oscar-2025-oscar-nominations/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-priyanka-chopra-backed-anuja-film-makes-it-to-oscar-2025-oscar-nominations-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ram-charan-game-changer-movie-review-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/ram-charan-game-changer-movie-review/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ram-charan-game-changer-movie-review-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ramayana-the-legend-of-prince-rama-new-release-date-trailer-cast-and-more-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/ramayana-the-legend-of-prince-rama-new-release-date-trailer-cast-and-more/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-ramayana-the-legend-of-prince-rama-new-release-date-trailer-cast-and-more-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-sky-force-first-review-akshay-kumar-and-veer-pahariya-film-is-an-emotional-masterpiece-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/sky-force-first-review-akshay-kumar-and-veer-pahariya-film-is-an-emotional-masterpiece/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-sky-force-first-review-akshay-kumar-and-veer-pahariya-film-is-an-emotional-masterpiece-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-solo-leveling-season-2-release-date-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/solo-leveling-season-2-release-date/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-solo-leveling-season-2-release-date-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-squid-game-on-netflixs-top-10-list-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/squid-game-on-netflix's-top-10-list/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-squid-game-on-netflixs-top-10-list-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-zendaya-and-tom-holland-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/entertainment/zendaya-and-tom-holland/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-entertainment-zendaya-and-tom-holland-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ca-icai-exam-result-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/ca-icai-exam-result/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ca-icai-exam-result-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-central-bank-of-india-so-recruitment-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/central-bank-of-india-so-recruitment-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-central-bank-of-india-so-recruitment-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-cuet-pg-exam-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/cuet-pg-exam-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-cuet-pg-exam-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-gate-exam-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/gate-exam-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-gate-exam-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-mp-esb-exam-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/mp-esb-exam/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-mp-esb-exam-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-nta-jee-mains-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/nta-jee-mains-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-nta-jee-mains-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-group-d-recruitment-notification-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/rrb-group-d-recruitment-notification-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-group-d-recruitment-notification-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-ntpc-admit-card-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/rrb-ntpc-admit-card-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-ntpc-admit-card-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-technician-grade-3-answer-key-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/rrb-technician-grade-3-answer-key-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-rrb-technician-grade-3-answer-key-2024-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-sbi-clerk-notification-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/sbi-clerk-notification-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-sbi-clerk-notification-2024-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-sbi-po-notification-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/sbi-po-notification-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-sbi-po-notification-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ssc-mts-result-2024-live-updates-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/ssc-mts-result-2024-live-updates/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ssc-mts-result-2024-live-updates-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ugc-net-exam-scheduled-for-january-15-postponed-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/ugc-net-exam-scheduled-for-january-15-postponed/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-ugc-net-exam-scheduled-for-january-15-postponed-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-upsc-civil-services-notification-2025-exam-dates-application-process-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/exams/upsc-civil-services-notification-2025-exam-dates-application-process/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-exams-upsc-civil-services-notification-2025-exam-dates-application-process-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-affordable-housing-scheme-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/affordable-housing-scheme/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-affordable-housing-scheme-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-bank-holiday-in-india-january-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/bank-holiday-in-india-january-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-bank-holiday-in-india-january-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-first-time-home-buyer-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/first-time-home-buyer/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-first-time-home-buyer-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-how-to-start-investing-in-stock-market-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/How-to-start-investing-in-stock-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-how-to-start-investing-in-stock-market-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/lulu-mall-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-maha-kumbh-mela-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/maha-kumbh-mela-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-maha-kumbh-mela-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-pitampura-new-delhi-overview-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/pitampura-new-delhi-overview/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-pitampura-new-delhi-overview-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/rajiv-gandhi-international-airport/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-real-estate-investment-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/real-estate-investment/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-real-estate-investment-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-solar-power-home-setup-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/solar-power-home-setup/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-solar-power-home-setup-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-tirumala-tirupati-devasthanams-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/tirumala-tirupati-devasthanams/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-tirumala-tirupati-devasthanams-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-what-is-ipo-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/what-is-ipo/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-what-is-ipo-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-cleanest-cities-in-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/cleanest-cities-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-cleanest-cities-in-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-flats-near-me-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/flats-near-me/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-flats-near-me-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-decor-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-decor/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-decor-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-maintenance-tips-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-maintenance-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-maintenance-tips-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-organization-ideas-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/home-organization-ideas/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-home-organization-ideas-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-interior-design-trends-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/interior-design-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-interior-design-trends-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/modular-kitchen-designs/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-smart-home-technology-trends-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/smart-home-technology-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-smart-home-technology-trends-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-apartment-gardening-tips-and-ideas-for-beginners-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/apartment-gardening-tips-and-ideas-for-beginners/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-apartment-gardening-tips-and-ideas-for-beginners-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/asparagus-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/money-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/passiflora-caerulea-flower/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/simple-rangoli-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-adani-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/adani-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-adani-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-capital-infra-trust-invit-ipo-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/capital-infra-trust-invit-ipo/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-capital-infra-trust-invit-ipo-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-digital-currency-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/digital-currency-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-digital-currency-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-dixon-technologies-share-price-analysis-dixcon-result-international-expansion-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/dixon-technologies-share-price-analysis-dixcon-result-international-expansion/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-dixon-technologies-share-price-analysis-dixcon-result-international-expansion-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-dmart-shares-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/dmart-shares/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-dmart-shares-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-indo-farm-equipment-ipo-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/indo-farm-equipment-ipo/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-indo-farm-equipment-ipo-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-iocl-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/iocl-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-iocl-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-kalyan-jewellers-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/kalyan-jewellers-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-kalyan-jewellers-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-larsen-and-turbo-l-and-t-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/larsen-and-turbo-l-and-t-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-larsen-and-turbo-l-and-t-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-netweb-technologies-stock-down-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/netweb-technologies-stock-down/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-netweb-technologies-stock-down-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-nifity-50-sensex-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/nifity-50-sensex/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-nifity-50-sensex-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-persistent-systems-stock-performance-analysis-january-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/persistent-systems-stock-performance-analysis-january-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-persistent-systems-stock-performance-analysis-january-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-tata-tech-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/tata-tech-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-tata-tech-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-zomato-share-price-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/market-insights/zomato-share-price/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-market-insights-zomato-share-price-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-6-9-magnitude-earthquake-hits-southwestern-japan-tsunami-advisory-issued-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/6-9-magnitude-earthquake-hits-southwestern-japan-tsunami-advisory-issued/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-6-9-magnitude-earthquake-hits-southwestern-japan-tsunami-advisory-issued-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-8th-pay-commission-salary-hike-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/8th-pay-commission-salary-hike/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-8th-pay-commission-salary-hike-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-aicte-revolutionizing-engineering-education-with-ai-in-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/aicte-revolutionizing-engineering-education-with-ai-in-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-aicte-revolutionizing-engineering-education-with-ai-in-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-anita-anand-might-replace-justin-trudeau-as-canadian-pm-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/anita-anand-might-replace-justin-trudeau-as-canadian-pm/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-anita-anand-might-replace-justin-trudeau-as-canadian-pm-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-aravind-srinivas-nandan-nilekani-ai-strategy-debate-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/aravind-srinivas-nandan-nilekani-ai-strategy-debate/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-aravind-srinivas-nandan-nilekani-ai-strategy-debate-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-atal-bihari-vajpayee-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/atal-bihari-vajpayee/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-atal-bihari-vajpayee-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-azerbaijan-airlines-plane-crash-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/azerbaijan-airlines-plane-crash/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-azerbaijan-airlines-plane-crash-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-bombay-high-court-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/bombay-high-court/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-bombay-high-court-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-budget-2025-live-updates-income-tax-salaried-employees-stock-market-railways-defence-real-estate-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/budget-2025-live-updates-income-tax-salaried-employees-stock-market-railways-defence-real-estate/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-budget-2025-live-updates-income-tax-salaried-employees-stock-market-railways-defence-real-estate-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-canada-pm-justin-trudeau-to-announce-resignation-on-january-6-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/canada-pm-justin-trudeau-to-announce-resignation-on-january-6/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-canada-pm-justin-trudeau-to-announce-resignation-on-january-6-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cds-bipin-rawat-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/cds-bipin-rawat/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cds-bipin-rawat-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-chatgpt-whatsapp-number-chatgpt-on-whatsapp-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/chatgpt-whatsapp-number-chatgpt-on-whatsapp/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-chatgpt-whatsapp-number-chatgpt-on-whatsapp-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cm-stalin-to-launch-pongal-gift-hamper-distribution-on-jan-9-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/cm-stalin-to-launch-pongal-gift-hamper-distribution-on-jan-9/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cm-stalin-to-launch-pongal-gift-hamper-distribution-on-jan-9-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cyclonic-storm-fengal-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/cyclonic-storm-fengal/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-cyclonic-storm-fengal-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-delhi-meerut-rrts-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/delhi-meerut-rrts/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-delhi-meerut-rrts-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-earthquake-in-nepal-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/earthquake-in-nepal/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-earthquake-in-nepal-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-grap-4-restrictions-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/grap-4-restrictions/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-grap-4-restrictions-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-gst-council-meeting-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/gst-council-meeting/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-gst-council-meeting-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-gurucharan-singh-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/gurucharan-singh/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-gurucharan-singh-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-human-metapneumovirus-hmpv-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/human-metapneumovirus-hmpv/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-human-metapneumovirus-hmpv-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-irctc-discontinues-compensation-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/irctc-discontinues-compensation/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-irctc-discontinues-compensation-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-isro-pslv-c-60-spadex-launch-today-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/isro-pslv-c60-spadex-launch-today/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-isro-pslv-c-60-spadex-launch-today-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-jalgaon-train-accident-pushpak-express-karnataka-express-maharashtra-news-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/jalgaon-train-accident-pushpak-express-karnataka-express-maharashtra-news/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-jalgaon-train-accident-pushpak-express-karnataka-express-maharashtra-news-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-jd-vance-oath-50th-us-vice-president-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/jd-vance-oath-50th-us-vice-president/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-jd-vance-oath-50th-us-vice-president-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kapil-sharma-rajpal-yadav-remo-dsouza-sugandha-mishra-death-threats-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/kapil-sharma-rajpal-yadav-remo-dsouza-sugandha-mishra-death-threats/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kapil-sharma-rajpal-yadav-remo-dsouza-sugandha-mishra-death-threats-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kekius-maximus-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/kekius-maximus/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kekius-maximus-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kia-syros-compact-suv-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/kia-syros-compact-suv/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-kia-syros-compact-suv-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-lal-bahadur-shastri-death-anniversary-11-january-mystery-of-tashkent-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/lal-bahadur-shastri-death-anniversary-11-january-mystery-of-tashkent/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-lal-bahadur-shastri-death-anniversary-11-january-mystery-of-tashkent-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-lambodara-sankashti-chaturthi-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/lambodara-sankashti-chaturthi-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-lambodara-sankashti-chaturthi-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mahakumbh-mela-2025-fire-accident-prayagraj-kumbh-news-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/mahakumbh-mela-2025-fire-accident-prayagraj-kumbh-news/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mahakumbh-mela-2025-fire-accident-prayagraj-kumbh-news-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-makar-sankranti-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/makar-sankranti-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-makar-sankranti-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-manmohan-singh-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/manmohan-singh/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-manmohan-singh-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mt-vasudevan-nair-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/mt-vasudevan-nair/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mt-vasudevan-nair-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mukesh-ambani-jio-jiocoin-polygon-launch-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/mukesh-ambani-jio-jiocoin-polygon-launch/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mukesh-ambani-jio-jiocoin-polygon-launch-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mukhya-mantri-ladki-bahin-yojana-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/mukhya-mantri-ladki-bahin-yojana/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-mukhya-mantri-ladki-bahin-yojana-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-nate-anderson-hindenburg-research-shutdown-adani-stock-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/nate-anderson-hindenburg-research-shutdown-adani-stock/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-nate-anderson-hindenburg-research-shutdown-adani-stock-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-one-nation-one-election-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/one-nation-one-election/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-one-nation-one-election-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pariksha-pe-charcha-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/pariksha-pe-charcha/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pariksha-pe-charcha-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-playback-singer-p-jayachandran-dies-at-80-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/playback-singer-p-jayachandran-dies-at-80/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-playback-singer-p-jayachandran-dies-at-80-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pm-awas-yojana-corruption-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/pm-awas-yojana-corruption/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pm-awas-yojana-corruption-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pm-svanidhi-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/pm-svanidhi/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-pm-svanidhi-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-republic-day-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/republic-day-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-republic-day-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-republic-day-parade-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/republic-day-parade-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-republic-day-parade-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-royal-enfield-himalayan-750-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/royal-enfield-himalayan-750/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-royal-enfield-himalayan-750-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-russian-cancer-vaccine-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/russian-cancer-vaccine/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-russian-cancer-vaccine-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-saif-ali-khan-attack-mumbai-home-health-condition-live-updates-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/saif-ali-khan-attack-mumbai-home-health-condition-live-updates/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-saif-ali-khan-attack-mumbai-home-health-condition-live-updates-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-sam-altman-ai-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/sam-altman-ai-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-sam-altman-ai-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-subhas-chandra-bose-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/subhas-chandra-bose/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-subhas-chandra-bose-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-sunita-williams-butch-wilmore-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/sunita-williams-butch-wilmore/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-sunita-williams-butch-wilmore-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-supreme-court-of-india-on-farmers-protest-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/supreme-court-of-india-on-farmer's-protest/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-supreme-court-of-india-on-farmers-protest-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-tirumala-tirupati-stampede-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/tirumala-tirupati-stampede/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-tirumala-tirupati-stampede-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-used-cars-gst-rate-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/used-cars-gst-rate/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-used-cars-gst-rate-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-ustad-zakir-hussain-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/ustad-zakir-hussain/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-ustad-zakir-hussain-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-v-narayanan-new-isro-chief-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/v-narayanan-new-isro-chief/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-v-narayanan-new-isro-chief-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-vivek-ramaswamy-doge-ohio-governor-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/news/vivek-ramaswamy-doge-ohio-governor/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-news-vivek-ramaswamy-doge-ohio-governor-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-abhishek-sharma-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/abhishek-sharma/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-abhishek-sharma-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-afghanistan-vs-zimbabwe-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/afghanistan-vs-zimbabwe/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-afghanistan-vs-zimbabwe-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-australia-open-2025-carlos-alcaraz-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/australia-open-2025-carlos-alcaraz/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-australia-open-2025-carlos-alcaraz-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-australia-women-vs-england-women-womens-ashes-au-w-vs-en-w-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/australia-women-vs-england-women-womens-ashes-au-w-vs-en-w/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-australia-women-vs-england-women-womens-ashes-au-w-vs-en-w-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-big-bash-league-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/big-bash-league/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-big-bash-league-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-delano-potgieter-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/delano-potgieter/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-delano-potgieter-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-elena-rybakina-australian-open-2025-loss-to-madison-keys-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/elena-rybakina-australian-open-2025-loss-to-madison-keys/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-elena-rybakina-australian-open-2025-loss-to-madison-keys-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-england-tour-of-india-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/England-Tour-of-India-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-england-tour-of-india-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-follow-on-rules-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/follow-on-rules/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-follow-on-rules-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-free-fire-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/free-fire-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-free-fire-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-gulf-t-20-championship-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/gulf-t20-championship/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-gulf-t-20-championship-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-hardik-pandya-ind-vs-eng-and-icc-champions-trophy-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/hardik-pandya-ind-vs-eng-and-icc-champions-trophy/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-hardik-pandya-ind-vs-eng-and-icc-champions-trophy-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-icc-champions-trophy-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/icc-champions-trophy-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-icc-champions-trophy-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-india-women-vs-ireland-women-ireland-women-tour-of-india-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/india-women-vs-ireland-women-ireland-women-tour-of-india-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-india-women-vs-ireland-women-ireland-women-tour-of-india-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-india-women-vs-west-indies-women-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/india-women-vs-west-indies-women/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-india-women-vs-west-indies-women-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-indian-super-league-2024-25-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/indian-super-league-2024-25/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-indian-super-league-2024-25-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ipl-2025-schedule-time-table-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/ipl-2025-schedule-time-table/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ipl-2025-schedule-time-table-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jaime-faria-australian-open-rising-tennis-star-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/jaime-faria-australian-open-rising-tennis-star/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jaime-faria-australian-open-rising-tennis-star-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jannik-sinner-italian-tennis-player-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/jannik-sinner-italian-tennis-player/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jannik-sinner-italian-tennis-player-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jasprit-bumrah-injury-update-news-and-substitute-rule-in-bgt-2024-25-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/jasprit-bumrah-injury-update-news-and-substitute-rule-in-bgt-2024-25/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-jasprit-bumrah-injury-update-news-and-substitute-rule-in-bgt-2024-25-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-kho-kho-world-cup-2025-full-schedule-teams-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/kho-kho-world-cup-2025-full-schedule-teams/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-kho-kho-world-cup-2025-full-schedule-teams-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-maheesh-theekshana-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/maheesh-theekshana/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-maheesh-theekshana-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nick-kyrgios-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/nick-kyrgios/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nick-kyrgios-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nitish-kumar-reddy-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/nitish-kumar-reddy/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nitish-kumar-reddy-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-novak-djokovic-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/novak-djokovic/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-novak-djokovic-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nternational-league-t-20-ilt-20-2025-teams-player-squads-fixtures-schedule-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/nternational-league-t20-ilt20-2025-teams-player-squads-fixtures-schedule/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nternational-league-t-20-ilt-20-2025-teams-player-squads-fixtures-schedule-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nz-vs-sl-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/nz-vs-sl/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-nz-vs-sl-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pakistan-vs-west-indies-test-series-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/pakistan-vs-west-indies-test-series/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pakistan-vs-west-indies-test-series-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pratika-rawal-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/pratika-rawal/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pratika-rawal-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pro-kabaddi-league-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/pro-kabaddi-league-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-pro-kabaddi-league-2024-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rachin-ravindra-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/rachin-ravindra/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rachin-ravindra-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ranji-trophy-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/ranji-trophy-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ranji-trophy-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rashid-khan-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/rashid-khan/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rashid-khan-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-real-madrid-vs-sevilla-la-liga-standings-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/real-madrid-vs-sevilla-la-liga-standings/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-real-madrid-vs-sevilla-la-liga-standings-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rishabh-pant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/rishabh-pant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rishabh-pant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rohit-sharma-return-domestic-mumbai-vs-jammu-kashmir-cricket-ranji-trophy-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/rohit-sharma-return-domestic-mumbai-vs-jammu-kashmir-cricket-ranji-trophy/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-rohit-sharma-return-domestic-mumbai-vs-jammu-kashmir-cricket-ranji-trophy-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ryan-rickelton-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/ryan-rickelton/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-ryan-rickelton-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-sa-20-league-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/sa20-league-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-sa-20-league-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-sanju-samson-champions-trophy-2025-vijay-hazare-absence-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/sanju-samson-champions-trophy-2025-vijay-hazare-absence/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-sanju-samson-champions-trophy-2025-vijay-hazare-absence-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-scott-boland-aus-vs-ind-bgt-2024-25-wtc-final-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/scott-boland-aus-vs-ind-bgt-2024-25-wtc-final-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-scott-boland-aus-vs-ind-bgt-2024-25-wtc-final-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-shubman-gill-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/shubman-gill/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-shubman-gill-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-tanush-kotian-cricketer-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/tanush-kotian-cricketer/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-tanush-kotian-cricketer-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-tommy-paul-australian-open-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/tommy-paul-australian-open-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-tommy-paul-australian-open-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-vijay-hazare-trophy-2024-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/vijay-hazare-trophy-2024-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-vijay-hazare-trophy-2024-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-virat-kohli-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/virat-kohli/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-virat-kohli-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-washington-sundar-cricketer-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/washington-sundar-cricketer/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-washington-sundar-cricketer-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-watch-viswanathan-anand-gukesh-praggnanandhaa-vidit-gujrathi-dance-in-viral-videos-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/watch-viswanathan-anand-gukesh-praggnanandhaa-vidit-gujrathi-dance-in-viral-videos/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-watch-viswanathan-anand-gukesh-praggnanandhaa-vidit-gujrathi-dance-in-viral-videos-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-world-test-championship-wtc-point-table-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/world-test-championship-wtc-point-table/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-world-test-championship-wtc-point-table-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-wpl-2025-schedule-teams-key-updates-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/wpl-2025-schedule-teams-key-updates/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-wpl-2025-schedule-teams-key-updates-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-wtc-final-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/wtc-final-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-wtc-final-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-yashasvi-jaiswal-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/sports/yashasvi-jaiswal/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-sports-yashasvi-jaiswal-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-apple-iphone-17-pro-max-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/apple iphone 17 pro max/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-apple-iphone-17-pro-max-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-microsoft-outlook-365-copilot-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/microsoft-outlook-365-copilot/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-microsoft-outlook-365-copilot-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-nvidia-geforce-rtx-50-series-5070-5090-graphics-cards-price-performance-release-date-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/nvidia-geforce-rtx-50-series-5070-5090-graphics-cards-price-performance-release-date/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-nvidia-geforce-rtx-50-series-5070-5090-graphics-cards-price-performance-release-date-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-oneplus-13-and-13-r-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/oneplus-13-and-13r/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-oneplus-13-and-13-r-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-poco-x-7-pro-poco-x-7-to-launch-in-india-today-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/poco-x7-pro-poco-x7-to-launch-in-india-today/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-poco-x-7-pro-poco-x-7-to-launch-in-india-today-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-pubg-mobile-update-3-6-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/pubg-mobile-update-3.6/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-pubg-mobile-update-3-6-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-realme-14-pro-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/realme-14-pro/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-realme-14-pro-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-samsung-galaxy-s-25-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/samsung-galaxy-S25/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-samsung-galaxy-s-25-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-arjun-erigaisi-wins-chess-world-with-wins-against-carlsen-caruana-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/arjun-erigaisi-wins-chess-world-with-wins-against-carlsen-caruana/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-arjun-erigaisi-wins-chess-world-with-wins-against-carlsen-caruana-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ayodhya-ram-mandir-celebrating-one-year-of-ram-lallas-pran-pratishtha-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/ayodhya-ram-mandir-celebrating-one-year-of-ram-lallas-pran-pratishtha/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ayodhya-ram-mandir-celebrating-one-year-of-ram-lallas-pran-pratishtha-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-chinese-new-year-2025-year-of-the-snake-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/chinese-new-year-2025-year-of-the-snake/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-chinese-new-year-2025-year-of-the-snake-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-christmas-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/christmas-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-christmas-2024-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-gukesh-dommaraju-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/gukesh-dommaraju/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-gukesh-dommaraju-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-india-vs-australia-border-gavaskar-trophy-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/india-vs-australia-border-gavaskar-trophy/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-india-vs-australia-border-gavaskar-trophy-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-national-consumer-day-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/national-consumer-day/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-national-consumer-day-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-national-youth-day-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/national-youth-day-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-national-youth-day-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/navratri-colours-2024-significance-day-wise-list/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/noel-tata-new-chairman-of-tata-trusts/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/ratan-tata-leadership-qualities/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-republic-day-sale-2025-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/republic-day-sale-2025/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-republic-day-sale-2025-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-uefa-champions-league-fixtures-2024-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/uefa-champions-league-fixtures-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-uefa-champions-league-fixtures-2024-index-mdx" */),
  "component---src-templates-blog-homepage-layout-jsx": () => import("./../../../src/templates/blog-homepage-layout.jsx" /* webpackChunkName: "component---src-templates-blog-homepage-layout-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-live-preview-jsx": () => import("./../../../src/templates/livePreview.jsx" /* webpackChunkName: "component---src-templates-live-preview-jsx" */),
  "component---src-templates-popular-blogs-layout-jsx": () => import("./../../../src/templates/popular-blogs-layout.jsx" /* webpackChunkName: "component---src-templates-popular-blogs-layout-jsx" */)
}

